.cards-qr-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}
.cards-qr-container .card-qr {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 5px solid #c08a1f;
    padding: 10px;
    padding-top: 0;
    border-radius: 10px;
}
.cards-qr-container .card-qr .img-qr {
    width: 200px;
    height: 200px;
    position: relative;
}
.cards-qr-container .card-qr .img-qr .img-qr-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    background-color: rgb(255, 255, 255);
}
.card-qr .img-qr .img-qr-layer .sure-qr {
    display: block;
    position: absolute;
    top: 35px;
    font-family: Quicksand ;
    font-weight: 800;
    text-shadow: 0px 0px 2px white;
    font-size: 17px;
}
.cards-qr-container .card-qr .img-qr .img-qr-layer svg {
    cursor: pointer;
    transition-duration:.3s;
    background-color: white;
    border-radius: 50%;
}
.cards-qr-container .card-qr .img-qr .img-qr-layer svg:first-child {
    color: rgb(206, 0, 0);
}
.cards-qr-container .card-qr .img-qr .img-qr-layer svg:last-child {
    margin-top: -1px;
    color: rgb(0, 189, 0);
}
.cards-qr-container .card-qr .img-qr .img-qr-layer svg:hover {
    scale: 1.2;
    transition-duration:.3s;
}
.cards-qr-container .card-qr img {
    width: 100%;
    height: 200px;
}
.cards-qr-container .card-qr .table-number-qr {
    font-family: Quicksand;
    margin-bottom: 10px;
    background-color: #c08a1f;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    font-weight: 600;
}
.cards-qr-container .buttons-container-qr {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
}
.cards-qr-container .card-qr .btn-qr {
    display: block;
    width: 70px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.cards-qr-container .card-qr .btn-qr.print-qr {
    background-color: rgb(0, 91, 209);
}
.cards-qr-container .card-qr .btn-qr.regen-qr {
    background-color: rgb(0, 182, 61);
}