.overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.26);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}
.button-pop-up {
    background-color: #cda34f;
    width: fit-content;
    padding: 3px 15px;
    margin: 0 auto;
    border-radius: 6px;
    color: white;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    transition-duration: .3s;
}
.button-pop-up:hover {
    background-color: #cca048;
    color: rgb(236, 236, 236);
    transform: scale(0.97);
    transition-duration: .3s;
}
.items-pop-up {
    position: fixed;
    background-color: rgb(240, 240, 240);
    color: white;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 350px;
    height: 320px;
    /* padding: 40px; */
    border-radius: 10px;
    z-index: 99999;
}
@media (max-width : 390px) {
    .items-pop-up {
        width: 95%;
    }
}
.items-pop-up p {
    background-color: #cda34f;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    font-size: 17px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.exit-icon {
    position: absolute;
    color: black;
    z-index: 1;
    font-weight: 900;
    font-size: 17px;
    right: 15px;
    top: 5px;
    cursor: pointer;
}
.items-container {
    overflow-y: auto;
    height: 200px;
}
.items-pop-up ul {
    width: 99%;
    list-style: none;
    text-align: start;
    color: black;
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    flex-wrap: wrap;
    align-items: center;
}
.items-pop-up ul li {
    background-color: rgb(224, 224, 224);
    padding: 5px 15px;
    margin: 5px;
    margin-bottom: 5px;
    width: 95%;
    width: fit-content;
}
.items-pop-up span {
    display: block;
    background-color:#dfad4b ;
    width: fit-content;
    padding: 7px 15px;
    font-size: 17px;
    font-weight: 700;
    border-radius: 4px;
    position: absolute;
    right: 29px;
    bottom: 8px;
    cursor: pointer;
    transition-duration: .3s;
}
.items-pop-up span:hover {
    transform: scale(.95);
    transition-duration: .3s;
    background-color: #cc9f45;
}
.display-none-pop {
    display: none;
}