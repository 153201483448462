.order-item {
    display: flex;
    align-items: center;
    width: fit-content;
    height: 41px;
    border: 1px solid #CDA34F;
    background: rgba(217, 217, 217, 0.30);
    border-radius: 8px;
    margin: 8px;
    padding-right: 15px;
}
.order-item img {
    width: 49px;
    height: 41px;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid #ECEDED;
    margin-left: -1px;
}
.order-item .quantity {
    color: #000;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-left: 8px;
}
.selected {
    background-color: #dc3545;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 50px;
    margin-left: auto;
    margin-right: 10px;
    margin-top: 20px;
    border-radius: 10px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer ;
    transition-duration: 0.4s;
}
.selected:hover {
    background-color: #b92f3d;
    transform: scale(0.97);
    transition-duration: 0.4s;
}
.layer1-overlay-archive {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000020;
    z-index: 999999;
}
.layer1-overlay-archive .are-u-sure-popup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: white;
    padding: 30px;
    border-radius: 20px;
    min-height: 170px;
}
.layer1-overlay-archive .are-u-sure-popup span {
 position: absolute;
 top: 3px;
 right: 10px;
 font-size: 20px;
 font-weight: 900;
 padding: 5px;
 cursor: pointer;
}
.layer1-overlay-archive .are-u-sure-popup p.text-sure {
    width: 300px;
    font-family: Poppins;
      text-align: center;;
    margin-top: 10px;
    font-size: 18px;
}
.layer1-overlay-archive .are-u-sure-popup .btns-Sure {
    display: flex;
    position: absolute;
    right: 20px;
    font-family: Poppins;
    bottom: 5px;
}
.layer1-overlay-archive .are-u-sure-popup .btns-Sure .yes-sure {
    background-color: #b92f3d;
    color: white;
    margin-left:20px;
    padding: 5px 20px;
    cursor: pointer;
    border-radius: 6px;
} 
.layer1-overlay-archive .are-u-sure-popup .btns-Sure .not-sure {
    margin-left:30px;
    padding: 5px 0px 5px 20px;
    cursor: pointer;
} 