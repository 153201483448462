.archive-section h2 {
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-left: 30px;
    width: 100%;
    margin-bottom: 30px;
}
.MuiDataGrid-footerContainer p{
    /* display: none; */
    /* display: flex; */
    /* align-items: center; */
    margin: 0;
}
.MuiDataGrid-columnHeaderTitle {
    color: #E1C895;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
}
.MuiDataGrid-row {
    color: #292D32;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
    margin: 5px 0;
}
.MuiDataGrid-row:hover {
    border-radius: 8px;
    background: rgba(205, 163, 79, 0.15) !important;
}
.title-mui {
    color: #CDA34F;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-left: 15px;
}
.order-archive-container {
    width: 100%;
    overflow: hidden;
}
.oreders-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.items-in-order {
    margin: 0px;
    margin-left: 25px;
    background-color: white;
    padding: 20px;
}
.colored {
    width: 100%;
    height: 2px !important;
    margin-left: -10px;
    background: rgb(195, 142, 37);
}
.title-details {
    color: #D1D1D1;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}
.title-details span {
    color: rgba(205, 163, 79, 0.96);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-left: 19px;
}
.route-cont {
    margin: 0 auto;
    width: 90%;
    justify-content: center;
    align-items: center;
    height: 100px;
}
.route-cont p {
    padding: 6px 20px;
    font-family: Poppins;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
    transform: scale(1);
      text-align: center;
}
.route-cont .p1{
    background-color: white;
    border: 2px solid #CDA34F;
    /* border-right: none; */
    border-radius: 8px 0 0 8px;
}
.route-cont .p2{
    background-color: white;
    border: 2px solid #CDA34F;
    /* border-left: none; */
    border-radius: 0px 8px 8px 0px;
}
.route-cont .p3{
    background-color: white;
    border: 2px solid #CDA34F;
    border-left: 1px solid #CDA34F;
    border-right: 1px solid #CDA34F;
    /* border-left: none; */
    /* border-radius: 0px 8px 8px 0px; */
}
.route-cont p.active-p {
    background-color: #CDA34F;
    color: white;
    animation-name: scaleChange;
    animation-duration: .3s;
    animation-fill-mode: forwards;
    animation-timing-function:cubic-bezier(0.23, 1, 0.320, 1) ;
    z-index: 2;
}
@keyframes scaleChange {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}
.table-container {
    margin-left: 20px;
}
@media (max-width : 550px) {
    .table-container {
        margin-left: 0;
    }
    .items-in-order {
        margin-left: 0 ;
        width: 100%;
    }
    .colored {
        margin-left: 0;
    }
}
.img-steps {
    scale: 1.5;
}
.css-2wmdnn-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
    justify-content: center;
}
.css-2wmdnn-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center;
}