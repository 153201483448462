/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #cda34f;
}
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a78541;
  }
.tables-container {
    height: 600px;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 0 10px;
    border-radius: 10px;
}
.table-styling {
    user-select: none;
    position: relative;
    width: 131px;
    height: 131px;
    margin: 20px;
    color: #b86e00;
    transition-duration: 0.4s;
    cursor: pointer;

}
.table-styling:hover {
    transform: scale(0.9);
    transition-duration: 0.4s;
}
.table-styling svg {
    position: absolute;
    top: 0;
    left: 0;
}
.table-styling svg:first-child {
    width: 131px;
    height: 131px;
}
.table-styling svg:last-child {
    top: 41px;
    left: 57px;
}
.table-styling .table-number {
    color: #000;
      text-align: center;
    font-family: Quicksand;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    position: absolute;
    top: 55px;
    left: calc(50% - 1px);
    transform: translateX(-50%);
}
.table-styling .d-none-not {
    opacity: 0;
}
.btnNotes {
    font-family: 'Quicksand', sans-serif;
}
.btnNotes .btnNote1 {
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #A77C27;
    color: #B8B8B8;
      text-align: center;
    font-family: Quicksand;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    padding: 8px 13px;
    outline: none;
}
.btnNotes .btnNote1:focus {
    /* color: white; */
    /* border: 1px solid #ddab47; */
    /* background-color: #ddab47; */
    /* outline: none; */
    transition-duration: .4s;
}
.btnNotes .btnNote1.active {
    transform: scale(1.07);
    font-weight: 900 !important;
    transition-duration: .3s;
}
.status {
    width: 86px;
    height: 23px;
    border-radius: 70px;
    color: #000;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin: 3px auto;
} 
.acnimate-class {
    animation-name: showedup;
    animation-duration: 1s;
    opacity: 0%;
    height: 0px;
    animation-fill-mode: forwards;
}
@keyframes showedup {
    0% {
        opacity: 0%;
        height: 0px;
    }
    100% {
        opacity: 100%;
        height: 100%;
    }
}
/* .status.pending {
    background: #E8D821;
}
.status.in_progress {
    background: #03AC00;
}
.status.done {
    background: #CDA34F;
}
.status.help {
    background-color: black;
    color: white;
    border: 2px solid rgba(205, 163, 79, 0.96);
    width: fit-content;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.status.bill {
    background-color: white;
    color: black;
    width: fit-content;
    padding: 7px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.status.privacy {
    background-color: white;
    color: black;
    width: fit-content;
    padding: 7px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #008de5;
}
.status.to_do {
    background-color: white;
    border: 2px solid rgb(0, 0, 0);
} */
.check-noti {
    width: 30px;
    height: 30px;
    cursor: pointer;   
}
.set-vanish {
    opacity: 0;
    height: 70px !important;
    transform: translateX(200%);
    transition-duration: 1s;
}
.card-order {
    background-color: white;
    position: relative;
    width: 148.898px;
    height: 155.278px;
    border-radius: 15px;
    filter: drop-shadow(0px 0px 12px rgba(205, 163, 79, 0.71));
    /* border: 4px solid #A77C27; */
    cursor: pointer;
}
.card-order.active-card {
    outline: 4px solid #03AC00;
}
.card-order img{
    width: 148.898px;
    height: 110.027px;
    border-radius: 12px 11px 0 0;
}
.card-order .note {
    position: absolute;
    background-color: white;
    top: 6px;
    left: 6px;
    padding: 8px 7px 8px 8.5px;
    border-radius: 25.5px 25.5px 0px 25.5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-order .note svg {
    margin-left: -2px;
} 
.card-order .note span {
    display: block;
    color: #A77C27;
      text-align: center;
    font-family: Quicksand;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-top: -2.5px;
    margin-left: 2px;
}
.card-order .check-box {
    width: 19px;
    height: 19px;
    position: absolute;
    top: 10px;
    right: 9px;
    background-color: white;
    border-radius: 3px;
    border: 2px solid #A77C27;
    overflow: hidden;
}
.card-order .check-box svg {
    width: 19px;
    height: 19px;
    position: absolute;
    top: -1.5px;
    left: -1.5px;
    opacity: 0;
    transition-duration: .3s;
}
.card-order.active-card .check-box svg {
    opacity: 100;
    transition-duration: .3s;
}
.card-order.active-card .check-box {
    background-color: #03AC00;
    border: 2px solid #03AC00;
    opacity: 100%;
    transition-duration: .3s;
}
.card-order .text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 27.5px;
}
.card-order .text .amount {
    color: #000;
      text-align: center;
    font-family: Quicksand;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -3px;
}
.card-order-list .text .amount {
    font-size: 15px;
    margin-top: 5px;
}
.card-order .text .amount span {
    display: block;
    margin-bottom: -6px;
    margin-right: 3px;
    margin-left: 2px;
}
.card-order .text .type {
    color: #000;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}
.card-order .text-together .amount {
    display: flex;
    justify-content: center;
    margin-right: 2px;
}
.card-order .text-together .amount span {
    margin-left: 2px;
    transform: translateY(1px);
}
.card-order .text-together {
    display: flex;
    position: absolute;
    color: #000982;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    justify-content: center;
    bottom: 5px;
    left: 20px;
    /* transform: translateX(-50%); */
}
.note.op-0 {
    opacity: 0;
} 
.cards-container-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.cards-container-list .card-order-list {
    cursor: pointer;
    width: 90%;
    height: 64px;
    display: flex;
    border-radius: 16px;
    border: 1px solid #FFF;
    background: rgba(205, 163, 79, 0.15);
    justify-content: space-between;
    align-items: center;
    padding: 8px 58px 8px 22px;
    position: relative;
}
.cards-container-list .card-order-list img{
    width: 49px;
    height: 48px;
}
.cards-container-list .card-order-list .text {
    display: flex;
    flex-direction: row-reverse;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    position: absolute;
    left: 110px;
    width: 170px;
    justify-content: space-between;
} 
.cards-container-list .card-order-list .note {
    margin-left: 130px;
    background-color: white;
    padding: 1px 4px;
    border-radius: 5px;
}
.list-order {
    width: 90%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: relative;
}
.list-order span {
    display: block;
    color: rgba(205, 163, 79, 0.60);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.card-order-list .note span{
    color: #A77C27;
      text-align: center;
    font-family: Quicksand;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-left: 2px;
}
.card-order-list .text-together {
    display: flex;
    color: #000982;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    justify-content: center;
    position: absolute;
    bottom: 7px;
    left: 90px;
}
.list-order span:nth-child(1) {
    padding-left: 110px;
}
.list-order span:nth-child(4) {
    padding-right: 18px;
}
.list-order span:nth-child(3) {
    padding-right: 50px;
}
@media (max-width : 1286px ) {
    .cards-container-list .card-order-list .text {
        width: 120px;
    }
    .cards-container-list .card-order-list .note {
        margin-left: 100px;
    }
}
@media (max-width : 1200px ) {
    .cards-container-list .card-order-list .text {
        width: 155px;
    }
    .cards-container-list .card-order-list .note {
        margin-left: 130px;
    }
}
@media (max-width : 1150px ) {
    .cards-container-list .card-order-list .text {
        width: 140px;
    }
    .cards-container-list .card-order-list .note {
        margin-left: 130px;
    }
}
@media (max-width : 1050px ) {
    .cards-container-list .card-order-list .text {
        width: 130px;
    }
    .cards-container-list .card-order-list .note {
        margin-left: 100px;
    }
}
@media (max-width : 1050px ) {
    .cards-container-list .card-order-list .text {
        width: 110px;
    }
    .cards-container-list .card-order-list .note {
        margin-left: 90px;
    }
}
@media (max-width : 900px ) {
    .cards-container-list .card-order-list .text {
        width: 180px;
    }
    .cards-container-list .card-order-list .note {
        margin-left: 160px;
    }
}
@media (max-width : 850px ) {
    .cards-container-list .card-order-list .text {
        width: 160px;
    }
    .cards-container-list .card-order-list .note {
        margin-left: 140px;
    }
}
@media (max-width : 800px ) {
    .cards-container-list .card-order-list .text {
        width: 140px;
    }
    .card-order-list  {
        /* height: fit-content !important; */
    }
    .text .type {
        max-width: 70px !important;
    }
    .amount {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cards-container-list .card-order-list .note {
        margin-left: 130px;
    }
}
@media (max-width : 750px ) {
    .cards-container-list .card-order-list .text {
        width: 120px;
    }
    .cards-container-list .card-order-list .note {
        margin-left: 90px;
    }
}
@media (max-width : 700px ) {
    .list-order span:nth-child(3) {
        padding-right: 20px;
    }
    .cards-container-list .card-order-list .note {
        margin-left: 110px;
    }
}
@media (max-width : 650px ) {
    .cards-container-list .card-order-list .text {
        width: 110px;
    }
    .cards-container-list .card-order-list .note {
        margin-left: 110px;
    }
}
@media (max-width : 650px ) {
    .cards-container-list .card-order-list .text {
        width: 100px;
    }
    .cards-container-list .card-order-list .note {
        margin-left: 110px;
    }
}
@media (max-width : 600px ) {
    .cards-container-list .card-order-list .text {
        width: 80px;
    }
    .cards-container-list .card-order-list .note {
        margin-left: 110px;
    }
}
@media (max-width : 550px ) {
    .list-order{
        display: none;
    }
    .text .type {
        font-size: 10px;
    }
}
.cards-container-list .card-order-list .text span {
    display: none;
}
.cards-container-list .card-order-list .check-box {
    width: 19px;
    height: 19px;
    border-radius: 3px;
    border: 2px solid #A77C27;
    background: #FFF;
    position: relative;
}
.cards-container-list .card-order-list .check-box svg {
    position: absolute;
    width: 18px;
    top: -3px;
    left: -1px;
    opacity: 0;
}
.card-order-list.active-card .check-box svg {
    opacity: 100;
    transition-duration: .3s;
}
.card-order-list.active-card .check-box {
    background-color: #03AC00;
    border: 2px solid #03AC00;
    opacity: 100%;
    transition-duration: .3s;
}
.card-order-list.active-card {
    outline: 2px solid #03AC00;
}
.d-none {
    display: none;
}
.captain-picker {
    display: flex;
    margin-bottom: -5px;
    margin-top: 10px;
    font-size: 17px;
    font-weight: 500;
    font-family: Poppins;
}
.captain-picker p {
    padding: 5px 15px;
    border: 2px solid #CDA34F;
    cursor: pointer;
}
.captain-picker p.rs {
    border-radius: 0px 5px 5px 0px ;
}
.captain-picker p.ls {
    border-radius: 5px 0px 0px 5px ;
}
.captain-picker p.active {
    background-color: #CDA34F;
    color: white;
    animation-name: scaleChange;
    animation-duration: .3s;
    animation-fill-mode: forwards;
    animation-timing-function:cubic-bezier(0.23, 1, 0.320, 1) ;
    z-index: 2;
}
@keyframes scaleChangeP {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}
.active-tab {
    background-color: #CDA34F !important;
    color: white !important;
}
.tabs2 .Mui-disabled {
    opacity: 100 !important;
}
.tabs2 .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled {
    pointer-events: all !important;
}
@media(max-width : 600px) {
    .order {
        font-size: 15px !important;
          text-align: center !important;
    }
}
.note-svg {
    position: static !important;
    margin-left: 10px;
}
.reset-btn-table {
    opacity: 0;
    margin:  0 2px;
    cursor: pointer;
    transition-duration: .3s;
}
.reset-btn-table:hover {
    transition-duration: .3s;
    transform: scale(1.3);
}
.table-gen {
    position: relative;
}
.table-gen:hover .reset-btn-table {
    transition-duration: .5s;
    transition-delay: .2s;
    opacity: 1;
}
@media only screen and (max-width: 768px) {
    .reset-btn-table {
            opacity: 100 !important;
            transform: scale(1.1) !important;
        }
    }
.add-new-table-overlay {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #00000045;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-new-table-box {
    width: 601px;
    height: 302px;
    border-radius: 20px;
    background: #FFF;
      text-align: center;
}
.add-new-table-box h3 {
    color: #000;
      text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 23px;
}
.add-new-table-box .btns-conf-container {
    display: flex;
    margin-top: 34px;
    justify-content: center;
    align-items: center;
}
.add-new-table-box .btns-conf-container .cancel-table {
    color: #A77C27;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    padding: 19px 36px 19px 33px;
    margin-right: 15px;
}
.add-new-table-box .btns-conf-container .yes-table {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 165px;
    height: 62px;
    background: linear-gradient(180deg, #CDA34F 3.52%, #A77C27 100%);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media only screen and (max-width: 768px) {
    .add-new-table-box {
            transform: scale(0.8) !important;
        }
        .add-new-table-box h3 {
            font-size: 18px;
            width: 80%;
            margin: 10px auto 0;
        }
        .add-new-table-box .btns-conf-container .yes-table {
            scale: .8;
        }
        .add-new-table-box .btns-conf-container .cancel-table {
            scale: .8;
        }
    }
@media only screen and (max-width: 400px) {
    .table-styling {
        margin: 5px;
    }
}