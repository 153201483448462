.search-items {
    width: 90% !important;
    margin: 0 auto;
    background-color: rgb(234, 234, 234);
    border: none;
    outline: none;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-family: Poppins;    
}
.search-items::placeholder {
    font-size: 14px;
    font-family: Poppins;
}
.image-item {
    margin-top: 30px;
    height: fit-content;
}
.image-item img {
    height: 100%;
}
.upload__image-wrapper {
    height:fit-content ;
}
.upload__image-wrapper img {
    height: 100%;
    margin-top: 30px;

}
.images-container-about-section {
    display: flex;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
}
.image-in-about {
    width: 250px !important;
    height: 200px !important;
}
.noti-container {
    transform: translateY(0%);
    transition-duration: .4s;
    transition-timing-function: ease-in-out;
    column-gap: 0px;
    top: -11px;
    left: -11px;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 10px;
}
@media(max-width : 1200px) {
    .noti-container {
        width: 100%;
    }
}
.noti-container::-webkit-scrollbar{
    display: none;
}
.noti-container .cont {
    display: flex;
    flex-direction: row-reverse;
    width: fit-content;
    position: relative;
}
.notification-merge {
    margin: 0 10px;
    transition-duration: .5s;
    transition-timing-function: ease-in-out;
    width: 600px;
    height: 72px;
    background-color: #cda34f45;
    z-index: 999999999999999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 24px;
    border: 2px solid #cda34f;
    border-radius: 30px 30px 30px 0px;
}
.notification-merge.Help {
    width: 300px !important;
    background-color: black;
    border: 2px solid rgba(205, 163, 79, 0.96);
}
.notification-merge.Help p {
    color: white;
}
.notification-merge.Help button {
    display: none;
    color: white;
}
.notification-merge.Privacy {
    width: 300px !important;
    background-color: #B9BCB9;
    border: 2px solid black;
}
.notification-merge .icon {
    background-color: white;
    padding: 5px;
    border-radius: 50%;
    margin-left: -10px;
}
.notification-merge.Privacy button{
    display: none;
}
.notification-merge.Bill {
    width: 300px !important;
    background: #BA9A55;
    border: 2px solid #9c772e;
}
.notification-merge.Bill button {
    display: none;
}
.notification-merge.Completed {
    background-color: rgb(16, 105, 250);
    border: 5px solid rgb(0, 0, 204);
    width: 340px !important;
}
.noti-container.down-noti {
    transform: translateY(0%);
    transition-duration: .4s;
    transition-timing-function: ease-in-out;
}
.notification-merge p {
    margin: 0;
    color: #000;
    font-family: Quicksand;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.notification-merge button {
    color: #FFF;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 40px;
    background: #03AB00;
    width: 179px;
    height: 36px;
    border: none;
}
.notification-merge button.back {
    width: 135px !important;
}
.content button {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 40px;
    background: #03AB00;
    width: 199px;
    height: 36px;
    border: none;
}
.w-0 {
    display: none;  
}
.see-more-button {
    font-family: Poppins;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 100px;
    border-radius: 65px;
    background-color: #cda34f;
    color: white;
}
.select-items-cont div:first-child {
    padding: 0%;
    padding-bottom: 5px;
    background-color: white;
}
.tags-items-container {
    display: flex;
    flex-wrap: wrap;
}
.tag-items {
    background-color: rgb(231, 231, 231);
    padding: 5px 15px;
    border-radius: 80px;
    font-family: Poppins;
    font-weight: 400;
    font-size: 13px;
    margin-top: 10px;
    margin-right: 10px;
}
.active_status {
    color: #03AB00;
    font-size: 13px;
    font-weight: 900;
    position: relative;
}
.active_status::before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: #03AB00;
    position: absolute;
    left: 90px;
    bottom: 50%;
    border-radius: 50%;
    transform: translateY(50%);
}
.last_seen {
    color: gray;
    font-size: 13px;
    font-weight: 700;
    position: relative;
}
.last_seen::before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: rgb(233, 19, 19);
    position: absolute;
    left: 0px;
    bottom: 50%;
    border-radius: 50%;
    transform: translateY(50%);
}
.user-end {
    cursor: pointer;
    padding: 10px 15px;
}
.user-end:hover svg path{
    fill: red;
}
.text-analytics-1 {
    position: absolute;
    right:  0px;
    top: 30px;
    font-family: Poppins;
    width: fit-content;
    width: 230px;
    height: 320px;
}
.text-analytics-1 h3 {
    color: #000;
    font-family: Poppins;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.text-analytics-1 p {
    color: #6F7881;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
/* width: 60%; */
}
.total-analytics {
    width: 100%;
    /* margin: 0 auto; */
    display: flex;
    /* justify-content: space-between; */
    /* flex-wrap: wrap; */
    font-family: Poppins;
    /* height: 100%; */
    /* margin-left: 20px; */
}
@media(max-width : 900px){
    .total-analytics {
        margin-top: 30px;
        flex-wrap: wrap;
    }
}
.total-analytics .item-total {
    display: flex;
    background-color: white;
    width: 313px;
    height: 115px;
    padding: 20px 20px;
    width: calc(50% - 20px);
    justify-content: space-between;
    align-items: center;
    margin: 0px 10px 20px 10px;
    border-radius: 8px;
    border: 1px solid rgba(205, 163, 79, 0.96);
    background: #FFF;
    min-width: 200px;
}
.total-analytics .item-total img {
    width: 60px;
    height: 60px;
}
.total-analytics .item-total p {
    color: #8A8B9F;
    font-family: Poppins;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}
.total-analytics .item-total .count-total {
    color: #3A3A3D;
    font-family: Poppins;
    font-size: 25px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.card-body-analytics {
    padding: 25px;
}
.card-body-analytics .title-analytics {
    display: flex;
    
}
.card-body-analytics .title-analytics .text-title {
    width: 60%;
}
.card-body-analytics .title-analytics h4 {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
}
.card-body-analytics .title-analytics p {
    color: #6F7881;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.card-body-analytics .title-analytics .filters-period {
    width: 55%;
}
.card-body-analytics .title-analytics .filters-period ul {
    list-style: none;
    display: flex;
    width: 226px;
    height: 40px;
    padding: 5px 13px;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    background: rgba(255, 232, 186, 0.96);
}
.card-body-analytics .title-analytics .filters-period ul li {
    /* padding: ; */
    color: #3A3A3D;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    transition-duration: .4s;
}
.card-body-analytics .title-analytics .filters-period ul li.active-li {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 7px; 
    background: rgba(205, 163, 79, 0.96);
    padding: 5px 10px;
    transition-duration: .4s;
}
.CircularProgressbar  {
    position: relative;
}
text.CircularProgressbar-text {
    font-family: Poppins;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transform: translateX(-22px) translateY(6px);
}
.analytics {
    display: flex;
    align-items: center;
    margin: 20px 10px;
}
.analytics .right-side-analytics {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
      text-align: center;
}
@media(max-width : 899px) {
    .analytics .right-side-analytics {
        margin-left: 60px;
    }
}
.analytics .right-side-analytics h5 {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
}
.analytics .right-side-analytics span {
    color: #6F7881;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.analytics .right-side-analytics p {
    color: #6F7881;
      text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 7px;
}
.bottom-side-analytics {
    display: flex;
}
.bottom-side-analytics .quan {
    margin-left: 10px;
    font-family: Poppins;
    border-radius: 11px;
    border: 1px solid #F2F2F2;
    width: 141px;
    height: 101px;  
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 16px;
}
.bottom-side-analytics .quan .num {
    color: #3A3A3D;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.bottom-side-analytics .quan .stat {
    color: #6F7881;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.summary-container {
    background-color: white;
    width: 100%;
    padding: 25px ;
    border-radius: 20px;
}
.summary-container h5 {
    color: #3A3A3D;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0px;
}
.summary-container p {
    color: #6F7881;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.summary-container .top-side-summary {
    user-select: none;
    display: flex;
    justify-content: space-between;
}
.summary-container .top-side-summary .selection {
    position: relative;
    margin: 0 40px 0 0;
}
.summary-container .top-side-summary .selected-vlaue {
    color: #A77C27;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    padding: 13px;
    border: 1px solid rgba(58, 58, 61, 0.14);
    border-radius:  9px;
    cursor: pointer;
    width: 100px;
}
.summary-container .top-side-summary .selected-vlaue  .icon {
    /* padding-left: 10px; */
    position: absolute;
    right: 10px;
    transform: rotate(0deg);
}
.animate-icon {
    animation-name: rotate-180deg ;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
}
@keyframes rotate-180deg {
    0%{
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}
.summary-container .top-side-summary ul {
    user-select: none;
    z-index: 999;
    list-style: none;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    /* border-radius: 10px; */
    border: 1px solid #b17805;
    width: 90px;
    margin-top: 2px;
    border-radius: 10px;
    background-color: white;

}
.summary-container .top-side-summary ul li {
    color: black;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 5px 10px;
    background-color: white;
    padding: 5px;
    width: 100%;
      text-align: center;
    cursor: pointer;
}
.summary-container .top-side-summary ul li:hover {
    background-color: #e8e8e8;
}
.summary-container .top-side-summary ul li:first-child {
    border-radius: 10px 10px 0 0;
    /* background-color: #03AB00; */
} 
.summary-container .top-side-summary ul li:last-child {
    border-radius: 0 0 10px 10px;
    /* background-color: #03AB00; */
} 
.summary-container .top-side-summary ul li:not(:last-child) {
    border-bottom: 1px solid #b1780541;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-progress.ant-progress-circle .ant-progress-text {
    color: #3A3A3D;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transform: translateY(0%);
}
.bottom-side-summary {
    height: 300px;
    width: 100%;
    position: relative;
}
.bottom-side-summary .income{
    color: rgba(205, 163, 79, 0.96);
      text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    display: block;
    top: 95px;
    left: 80px;
}
.roun1 .ant-progress-text{
    display: none;
}
.roun2 .ant-progress-text{
    display: none;
}
.bottom-side-analytics  {
    position: relative;
}
.roun1 , .roun2 , .roun3 {
    position: absolute;
    left: 60px;
    top: 70px;
}
.roun1{
    scale: 1.8;
}
.roun2 {
    scale: 1.4  ;
}
.summary-container .right-side-bottom-summary {
    position: absolute;
    right: 50px;
    top: 60px;
}
.right-side-bottom-summary .rectangle {
    width: 10px;
    height: 25px;
    border-radius: 8px;
    
}
.right-side-bottom-summary .income1 {
    display: flex;
    width: 90px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}
.right-side-bottom-summary .income1 .num2 {
    color: #3A3A3D;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.right-side-bottom-summary .income1 .income-span {
      text-align: center;
    color: #A9A4A3;
    font-family: Poppins;
    font-size: 10.809px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.text-income {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.grid1 {
    max-width: calc(50% - 20px) !important;
}
@media(max-width : 899px) {
    .grid1 {
        max-width: 100% !important;
    }
}
.css-1cl05x7 {
    border-bottom: none;
}
.waiters-analytics{
    display: flex;
    align-items: center;
}
.right-side-waiters {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.left-side-waiters {
    width: 50%;
}
.waiter-list {
    margin-top: 21px;
    margin-left: 17px;
    list-style: none;
}
.waiter-list li {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 14px;
    padding-top: 14px;
}
.waiter-list li:not(:last-child) {
    border-bottom: 1px solid rgba(111, 120, 129, 0.14);
}
.waiter-list li span {
    color: #292D32;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
    display: block;
}
.waiter-list li .rank img{
    width: 30px;
    height: 30px;
}
.waiter-list li .rank {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.right-total  {
    color: #6F7881;
      text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.right-side-waiters .CircularProgressbar-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-4px);
    display: block;
}
.right-span-flow {
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    color: #6F7881;
      text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.date-picker-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000a3;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rdrStaticRanges{
    font-family: Poppins;
    font-weight: 500;
}
.content-date-picker {
    position: relative;
}
.rdrDateRangePickerWrapper {
    padding: 40px;
    background-color: white;
    border-radius: 20px;
}
.rdrDayNumber span {
    font-family: Poppins;
    font-weight: 500;
}
.close-date-picker {
    width: 25px;
    height: 25px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    right: 15px;
    scale: 1.3;
    cursor: pointer;
}
@media(max-width : 950px) {
    .rdrDefinedRangesWrapper {
        display: none !important;
    }
}
@media(max-width : 750px) {
    .rdrMonths.rdrMonthsHorizontal {
        flex-direction: column;
    }
    .rdrDateRangePickerWrapper {
        padding: 20px;
    }
    .close-date-picker {
        background-color: transparent;
        top: 5px;
        right: 5px;
    }
}
.right-span-flow2 {
    display: block;
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
    color: #3A3A3D;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-picker:hover, :where(.css-dev-only-do-not-override-pr0fja).ant-picker-focused {
    border-color: #deb45f;
}
.ant-picker-input input {
    color: #000;
    font-family: Poppins;
    font-weight: 500;
}
.ant-picker-input svg  {
    fill: #deb45f;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-pr0fja).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-pr0fja).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #deb45f;
}
@media(max-width : 469px) {
    .total-analytics {
        margin-top: 0;
        justify-content: center;
    }
    .total-analytics .item-total {
        height: 80px;
        padding: 10px;
        margin: 0 5px 10px;
        width: calc(50% - 10px);
    }
    .total-analytics .item-total .count-total{
        font-size: 16px !important;
    }
    .total-analytics .item-total p {
        font-size: 13px !important;
    }
    .total-analytics .item-total img {
        width: 40px;
        height: 40px;
    }
}
@media(max-width : 449px) {
    .total-analytics .item-total {
        margin: 0 2px 10px;
    }
}
@media(max-width : 437px) {
    .summary-container .right-side-bottom-summary {
        display: flex;
        justify-content: center;
        top: auto;
        bottom: -20px;
        left: 0;
        width: 100%;
        /* transform: translateX(-50%); */
    }
    .total-analytics .item-total {
        width: 100%;
    }
    .d-none-mobile-circ {
        display: block;
    }
    .waiters-analytics {
        flex-direction: column-reverse;
    }
    .left-side-waiters {
        width: 100%;
    }
    .waiter-list {
        margin: 10px 0 0 0;
    }
    .right-side-bottom-summary .income1 {
        margin-left: 10px;
    }
}
@media(max-width : 370px) {
    .content {
        scale: .9;
        transform: translateX(-20px) scale(.9);
    }
}
@media(max-width : 340px) {
    .content {
        scale: .9;
        transform: translateX(-30px) scale(.9);
    }
}
@media(max-width : 320px) {
    .content {
        scale: .9;
        transform: translateX(-40px) scale(.9);
    }
}
@media(max-width : 300px) {
    .content {
        scale: .8;
        transform: translateX(-50px) translateY(-20px) scale(.9);
    }
}