@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/Helvetica.ttf');
}

body {
  font-family: 'Helvetica' !important;
  background-color: aliceblue;
}
a {
  text-decoration: none;
  color: #13141f;
}

a:hover {
  color: #cda34f;
}

.bg-login .MuiInputBase-root {
  color: #fff;
}

.MuiTypography-root,
.MuiFormLabel-root {
  /* font-family: 'Helvetica' !important; */
}

nav .MuiPaper-root,
.MuiDrawer-root .MuiPaper-root {
  background: black;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  padding-top: 2rem;
}

nav a,
.MuiDrawer-root a {
  color: #fff !important;
}

nav .MuiListItemButton-root.active,
.MuiDrawer-root .MuiListItemButton-root.active {
  background-color: #cda34f;
}

header {
  box-shadow: 0 0 2px 2px #4c4c4797 !important;
}
.bg-login {
  background-image: url('./Group\ 1000001380.jpg');
  align-items: center;
    background-color: #00000077;
    background-position: 50%;
    background-size: cover;
    color: #fff;
    display: flex;
    min-height: 100vh;
    text-align: -webkit-center;
    text-align: -moz-center;
}

.box-form {
  background: #131313;
  padding: 2rem 3rem;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #131313;
}

.btn-bg {
  background: #cda34f;
  font-size: 18px;
  font-family: 'Helvetica';
  box-shadow: none !important;
}

.btn-bg:hover {
  background: #4c4c47;
}

.btn-add {
  background: #cda34f;
}

.btn-add:hover {
  background-color: #4c4c47;
}

.box-cat img {
  height: 250px;
  object-fit: cover;
}

.list {
  top: 1em;
  right: 1em;
}

.box-img::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  background-color: #13141f8b;
  border-radius: 0.25rem;
}

.upload__image-wrapper img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.notifi-btn .MuiSvgIcon-root {
  color: #cda34f;
  font-size: 40px;
}
.MuiStack-root.css-3lq27p-MuiStack-root button.MuiButtonBase-root:focus {
  background-color: transparent;
} 

.MuiBadge-badge {
  top: 5px;
  right: 5px;
  background-color: #4c4c47;
}

.about-grid h6 svg {
  background: #cda34f;
  color: #fff;
  font-size: 25px;
  padding: 1px;
  margin-right: 10px;
}

.toast-close {
  background: #000;
  color: #fff;
  border: none;
  padding: 5px;
}

.order-num {
  position: absolute;
  left: 0;
  top: 0;
  background: #cda34f;
  padding: 5px;
  display: inline-block;
  color: #fff;
  border-radius: 0.25rem !important;
}

.list-table {
  background: #cda34f;
  font-weight: 700;
  padding: 5px 10px;
  font-size: 17px;
  color: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
}

.list-table button {
  float: right;
}

.lang-inputs .MuiSvgIcon-root {
  position: absolute;
  right: 0;
  right: 10px;
  top: 30px;
  color: #cda34fd1;
}

.lang-inputs .MuiGrid-item {
  position: relative;
}
.lang-inputs .btn-add {
  width: 100%;
  font-size: 17px;
  padding: 10px;
}
.nameTable{
  font-family: Poppins;
font-size: 32px;
font-weight: 600;
line-height: 48px;
letter-spacing: 0em;}
.css-6d3pg0-MuiTabs-indicator {
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
 background-color: transparent;


}

.tab-buttons{
  display: flex;
}
.tab-button:focus {
  background-color: transparent;
}
.tab-button svg{
  width: 100%;
}
button.activeSVG {
  background-color: rgba(205, 163, 79, 0.96) !important;
}
button.activeSVG svg path {
  fill: white !important;
}
/* .css-52jy86{
    cursor: pointer !important;
    font-weight: 700 !important;
    line-height: 1.7142857142857142 !important;
    font-size: 0.875rem !important;
    text-transform: capitalize !important;
    font-family: Public Sans,sans-serif !important;
    padding: 6px 16px !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    color: #CDA34FF4 !important;
    background-color: white !important;
    box-shadow: 0px 3px 1px -2px rgba(145, 158, 171, 0.2), 0px 2px 2px 0px rgba(145, 158, 171, 0.14), 0px 1px 5px 0px rgba(145, 158, 171, 0.12) !important;
    box-shadow: 0 8px 16px 0 rgba(32, 101, 209, 0.24) !important;
    border: 1px solid #CDA34FF4 !important;
} */
/* .css-7yr25s {
  color: #A77C27 !important;
} */
.nameTable .css-wdsdfe-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #cda34fb5;
    font-size: 19px;
}
.statusTable{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 169px;
  height: 49px;
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  -o-border-radius: 9px;
  cursor: pointer;
  color: #FFF;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.statusTable.in_progress {
  background: #03AB00;
}
.statusTable.pending {
  background: #E8D821;
}
.statusTable.busy_table {
  background: #CDA34F;
}
.nameTable .css-ptiqhd-MuiSvgIcon-root {
    color: #cda34fb5;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 2.25rem;
}
.card1 {
border: 1px solid #ccc;
    border-radius: 20px;
    /* margin: 20px; */
    /* margin-right: 40px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 49px;
    height: 49px;
    border: 1px;
    box-shadow: 0px 0px 12px 0px #cda34fb5;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.card {
  border: 1px solid #ccc;
  border-radius: 20px;
  margin: 15px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 143;
  height:136px;
  border-radius: 15px 15px 0px 0px;
  border: 1px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  box-shadow: 0px 0px 12px 0px #cda34fb5;
}

.card-image {
  /* width: 100%;
  height: 200px; */
  width: 149px;
  height: 103px;
  object-fit: cover;
  /* margin-bottom: 16px; */
}

.card-name {
  background: linear-gradient(0deg, #FFE1A5, #FFE1A5),
linear-gradient(0deg, rgba(255, 225, 165, 0.958352), rgba(255, 225, 165, 0.958352));

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  /*font-weight: bold;
   */
    text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 10px 10px;
  -webkit-border-radius: 0px 0px 10px 10px;
  -moz-border-radius: 0px 0px 10px 10px;
  -ms-border-radius: 0px 0px 10px 10px;
  -o-border-radius: 0px 0px 10px 10px;
}
.card-name1 {
  background: white;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  /*font-weight: bold;
   */
    text-align: center;
  width: 140px;
  height: 100%;
  border-radius: 0px 0px 10px 10px;
  -webkit-border-radius: 0px 0px 10px 10px;
  -moz-border-radius: 0px 0px 10px 10px;
  -ms-border-radius: 0px 0px 10px 10px;
  -o-border-radius: 0px 0px 10px 10px;
}

.number {
  font-weight: 900;
}
.card-note {
  position: absolute;
  margin: 5px;
  left: 9px;
}
.total {
  display: flex;
  align-items: center;
  color: #C1C1C1;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.notes-title {
  color: rgba(205, 163, 79, 0.96);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.number-title {
  margin-left: 10px;
  display: block;
}
.counter{
  font-family: Poppins;
font-size: 18px;
font-weight: 600;
line-height: 27px;
letter-spacing: 0em;
text-align: left;

}
.counter1{
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
}
.note-content {
  border-radius: 5px;
  border: 1px dashed rgba(205, 163, 79, 0.96);
  background: #FBFAFA;
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
  padding: 12px 29px 20px 25px;
  margin: 16px auto;
  width: 100%;
}
.delete-button-order {
  cursor: pointer;
  background-color: #EC5A5A;
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  display: block;
  border-radius: 10px;
  margin-right: 10px;
  width: 80%;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.delete-button-order:hover {
  background-color: #b24242;
}
.complete-order {
  display: block;
  cursor: pointer;
  width: 120%;
  height: 62px;
  border-radius: 10px;
  background: #529FF9;
  color: white;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}
button.bb:focus {
  background-color: transparent !important;
}
.complete-order:hover {
  background: #3e79bd;
}
.there-no {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  color: #4c4c47;
}
.Alert{
  height: 71px;
    width: 485px;
    max-width: 485px;
    background-color: antiquewhite;
    display: flex;
    justify-content: inherit;
    align-items: baseline;
    border-radius: 49px;
    padding: 14px;
   
    display: flex;
    justify-content: inherit;
    padding-left: 27px;
    padding-right: 32px;
    -webkit-border-radius: 49px;
    -moz-border-radius: 49px;
    -ms-border-radius: 49px;
    -o-border-radius: 49px;
     border-bottom-left-radius: 0px;
     border: 2px solid #CDA34F;
}
.Alert2{ font-family: Poppins; font-size: 14px; font-weight: 600; line-height: 21px; letter-spacing: 0em; background-color: green; padding: 10px; color: white; border-radius:30px ; -webkit-border-radius:30px ; -moz-border-radius:30px ; -ms-border-radius:30px ; -o-border-radius:30px ;
}
.Alert1{ font-family: Quicksand;
font-size: 17px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
text-align: left;

}
.note-content2 {
  margin-top: 20px;
  height: 70px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  background: #03AB0040;
box-shadow: 0px 0px 12px 0px #CDA34F36;
font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
display: flex;
justify-content: center;
align-items: center;


}
.note-content1 {
  margin-top: 20px;
  height: 70px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
background: #529FF940;

box-shadow: 0px 0px 12px 0px #CDA34F36;
font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
display: flex;
justify-content: center;
align-items: center;


}
.notes1 {
  margin: 0px;
  height: auto;
  /* width: 419px; */
  padding: 23px;
  background-color: white;
}
 .arrowOrder{
  width: 48px;
  height: 48px;
  background-color:#CDA34FF4 ;
  border-radius: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin: 16px 32px;

 }

.tabs2 .css-wdsdfe-MuiButtonBase-root-MuiTab-root.Mui-selected {
  border-radius:5px !important;
padding:8px 20px 10px 20px !important;
color:black !important;
background-color:#CDA34FF4 !important;

}
.tabs2 .css-wdsdfe-MuiButtonBase-root-MuiTab-root {
      /* margin-top: 20px; */
    font-weight: 700;
    line-height: 1.25;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-family: Public Sans,sans-serif;
    
    position: relative;
    border-radius:5px;
padding:8px, 20px, 10px, 20px;
    color:  #B8B8B8;
    
 

}


.tabs2  .css-ptiqhd-MuiSvgIcon-root {
    color:  #A77C27;
  
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 2.25rem;
}


.tabs2 .css-12vebo6-MuiButtonBase-root-MuiButton-root {
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    font-weight: 700;
    line-height: 1.7142857142857142;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-family: Public Sans,sans-serif;
  
    padding: 6px 16px;
    
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #CDA34FF4;
    background-color: white;
    box-shadow: 0px 3px 1px -2px rgba(145, 158, 171, 0.2), 0px 2px 2px 0px rgba(145, 158, 171, 0.14), 0px 1px 5px 0px rgba(145, 158, 171, 0.12);
    box-shadow: 0 8px 16px 0 rgba(32, 101, 209, 0.24);
    border: 1px solid #CDA34FF4;
    
}



.tabs2 .css-17w17gr-MuiButtonBase-root-MuiButton-root {
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    width: 47px;
height: 33px;

    padding: 6px 16px;
       border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
       color: #CDA34FF4;
    background-color: transparent;
    box-shadow: 0px 3px 1px -2px rgba(145, 158, 171, 0.2), 0px 2px 2px 0px rgba(145, 158, 171, 0.14), 0px 1px 5px 0px rgba(145, 158, 171, 0.12);
    box-shadow: 0 8px 16px 0 rgba(51, 102, 255, 0.24);
    border: 1px solid #CDA34FF4
}


.tablecard .css-1g3e3gh-MuiTableCell-root {
    line-height: 1.5rem;
    font-size: 0.875rem;
    font-family: Public Sans,sans-serif;
    font-weight: 600;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(241, 243, 244, 1);
    text-align: left;
    padding: 16px;
    color: #212B36;
    color: #637381;
    background-color: transparent;}

.tablecard .head{
      
   text-align: center;
  color: #CDA34F99;

}


.tablecard .css-apqrd9-MuiTableBody-root {
   
    border: 1px;
    border-radius: 16px;

}
.table-row {
  background-color: antiquewhite;
  border-radius: 50px !important;
  margin-bottom: 10px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}

  button:focus:not(:focus-visible) {
  /* background-color:  #CDA34FF4; */
  /* color:black; */
  /* outline: 0; */
}
.table-row .css-1uoh5bq-MuiTableCell-root {
    line-height: 1.5714285714285714;
    font-size: 0.875rem;
    font-family: Public Sans,sans-serif;
    font-weight: 400;
    display: table-cell;
    vertical-align: inherit;
    text-align: center;
    padding: 16px;
    color: #212B36;}
.tablecard1 .css-1uoh5bq-MuiTableCell-root {
    line-height: 1.5714285714285714;
    font-size: 0.875rem;
    font-family: Public Sans,sans-serif;
    font-weight: 400;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(241, 243, 244, 1);
    text-align: center;
    padding: 16px;
    color: #212B36;}


.button-clicked{
  background-color: #03AB00;
}





.imgTable{
  width:136px;
  
}

.AllTable1{
      margin-bottom: 16px;
    background: #EAF0F0;
    border-radius: 15px;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.AllTable1 p{
  font-family: Poppins;
line-height: 20px;
letter-spacing: 0em;
color: black;
text-align: center; 
font-size: 11px;
font-style: normal;
font-weight: 600;
text-transform: capitalize;
}
.AllTable1 .dataTable{
 font-family: Poppins !important;
font-size: 18px;
font-weight: 700;
line-height: 27px;
letter-spacing: 0em;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;


}
.AllTable1 .dataTable1{
   margin-top: 0px;
  margin-bottom: 0px;
 font-family: Poppins;

 padding: 8px 12px;


 letter-spacing: 0em;
 background-color: green;


font-size: 11px;
font-weight: 600;
line-height: 17px;
letter-spacing: 0em;


 border-radius:20px ;
 -webkit-border-radius:20px ;
 -moz-border-radius:20px ;
 -ms-border-radius:20px ;
 -o-border-radius:20px ;
}
.AllTable1 .dataTable2{
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 2px 10px;
 letter-spacing: 0em;
 font-family: Poppins;
font-size: 11px;
font-weight: 600;
line-height: 17px;

 background-color: #E8D821;
 border-radius:20px ;
 -webkit-border-radius:20px ;
 -moz-border-radius:20px ;
 -ms-border-radius:20px ;
 -o-border-radius:20px ;
}


  .TableContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
      text-align: center;
  }
  
.TableContent .nametable{
  /* position: absolute; */
  /* margin-top: 16px; */

font-size: 16px;
font-weight: 500;

letter-spacing: 0em;
  text-align: center;
color: #A2774C;

}
.iconname{
      display: flex;
    flex-direction: column;
    position: absolute;
}
.iconTable{
  width: 12px;
  margin-left: auto;
  margin-right: auto;
}


.tr {
  display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}


.btnNote{
height: 32px;
/* padding: 4px 6px 4px 6px; */
display: flex;

align-content: center;
align-items: center;
background-color: transparent;
border: 1px solid  #CDA34FF4;
border-radius: 5px;
color: gray;

}
.paginate-right,.paginate-left{
  background-color: transparent;
  border: none;
  color: #CDA34FF4;
}

.numberTable{
  padding: 10px;
  color:white;
  display: flex;
  justify-self: center;
  align-items: center;
  width: 73px;
height: 110px;
border-radius: 0px 0px 11px 0px;
background: #CDA34F;
flex-direction: column;
margin-right: 20px;

}

/* 
.AllTable1 .css-aszp7s-MuiTable-root {
    margin: 5px;
    display: table;
    width: 93%;
    border-collapse: collapse;
    border-spacing: 0;

} */



.AllTable1 .css-1uoh5bq-MuiTableCell-root {
  line-height: 1.5714285714285714;
  font-size: 0.875rem;
  font-family: Public Sans,sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  border-bottom: none !important;
  padding: 10px 0;
  color: #212B36;}
.numberTable .number1{
  /* font-family: Quicksand; */
font-size: 9px;
font-weight: 600;
line-height: 11px;
letter-spacing: 0em;




}
.css-1cl05x7 {
  padding: 10px 0 !important;
}



.newOrders{
  margin-top: 20px;
  height: 38px;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  /* align-items: flex-start; */
  background-color:  #FF00003D;
width: 222px;
  padding: 10px 31px;

font-size: 17px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;


}



.numberTable .number2{
  /* font-family: Quicksand; */
  font-size: 84px;
  font-weight: 600;
  line-height: 55px;
  letter-spacing: 0em;
 
  



}

.css-wg4xta-MuiGrid-root {
  padding: 10px;
  margin-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
 /* background-color: white; */
 flex-basis: 33.333333%;
 flex-grow: 1;
 /* max-width: 33.333333%; */

}

.css-mhc70k-MuiGrid-root {
  margin-top: 31px;
  box-sizing: border-box;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  margin-top: 12px;
  width: calc(100% + 0px);
  margin-right: 0px;
  margin-left: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}


.css-o4bg9w-MuiGrid-root {
margin-top: 33px;
border-radius: 12px;
border: 1px;
border: 1px solid #A77C2736;
background: 
linear-gradient(0deg, #FFFFFF, #FFFFFF);


}

.css-aszp7s-MuiTable-root {
  margin: 10px;
  display: table;
  width: 95%;
  border-collapse: collapse;
  border-spacing: 0;
}
.grid-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.note-content1
 {
  background-color: #f2f2f2;
  padding: 10px;
    text-align: center;
}

.not{
  position: absolute;
  background-color: white;
  font-size: 9px;
  margin-top: 52px;
  padding: 3px 9px 3px 10px;
  border-radius: 64px;
  border-bottom-right-radius: 0px;
}
.green-border{
  border: 1px solid green;
  
}
.green-border1{
  border: 3px solid green;
  
}

.css-m9hi0w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-m9hi0w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: green;
  
}

.newOrdersNumber1{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.merg{
  background-color:  #03AB00;
  
  border:none;
  padding: 10px;
  margin-right: 10px;
color:white;
font-size: 14px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
border-radius: 40px;


}
.back{
  background-color:  #CDA34FF4 !important;
  border-radius: 40px;
  border:none;
  padding: 10px;
  margin-right: 10px;
  color:white;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
letter-spacing: 0em;
width: 180px !important;
}
.css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-left: 0px;
}
@media (min-width: 1200px) {
  .css-1oqqzyl-MuiContainer-root {
    /* max-width: 2400px; */
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (min-width: 1200px)
{
 .css-wg4xta-MuiGrid-root {
     padding: 10px;
     margin-top: 20px;
     padding-left: 0px;
     padding-right: 0px;
    /* background-color: white; */
    flex-basis: 33.333333%;
    flex-grow: 1;
    /* max-width: 33.333333%; */
   
}}


.css-mhc70k-MuiGrid-root {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 12px;
  width: calc(100% + 0px);
  margin-right: 0px;
  margin-left: 0px;
  display: flex;
  justify-content:flex-start;
  align-items: flex-start;
}
.tablecard .css-mhc70k-MuiGrid-root {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 12px;
  width: calc(100% + 0px);
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.card-checkbox {
  position: absolute;
  top: -1px;
  right: -1px;
}




/* @media (min-width: 1200px){
.css-wg4xta-MuiGrid-root {
    padding: 10px;
    padding-left: 0px;
    padding-right: 0px;
    background-color: white;
    /* height: 86vh; */
    /* flex-basis: 36.333333%;
    flex-grow: 0;
    max-width: 46.333333%;
}} */ 


@media (max-width: 1200px)
{
.onetwotwo {
  display: flex;
  place-content: center;
  flex-wrap: wrap;
}

}
.MuiStack-root.css-63kp0z button:focus:not(:focus-visible) {
  background-color: transparent;
  color: #000;
  outline: 0;
}
.tab-buttons {
  height: 33px;
}
.tab-buttons button:focus:not(:focus-visible) {
  background-color: transparent;
}
.tab-buttons .tab-button {
  background-color: white !important;
  border: 1px solid #CDA34F !important;
}
.tab-buttons .tab-button#svg2 {
  border-radius: 0px 4px 4px 0px !important;
}
.tab-buttons .tab-button#svg1 {
  border-radius: 4px 0px 0px 4px !important;
}
.tab-buttons .tab-button.activeSVG {
  background-color: #CDA34F !important;
}
.MuiTabs-indicator.css-1p30j7e {
  display: none;
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.btnSlider.css-1mp0xne{
  border-radius: 7px;
}
.css-1mp0xne.Mui-selected {
  color: #000 !important;
}
.all-togather {
  position: absolute;
  background-color: #529FF9;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 4px;
  bottom: 50px;
}
.amount-togather {
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding: 0;
  margin-left: 3px;
}
.card-order-list .all-togather{
  left: -21px;
  bottom: 10px;
}
.css-dxfs78::-webkit-scrollbar {
  display: none;
}
.email-logOut {
  color: #7A7A7A;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: lowercase;
  margin-top: -5px;
}
.name-logOut {
  color: rgba(205, 163, 79, 0.96);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.hr-logOut {
  background: rgba(205, 163, 79);
  width: 100%;
  margin: 0;
}
.span-logOut {
  color: #7A7A7A;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-left: 35px;
}
